html, body {
    margin: 0;
    /*scroll-behavior: smooth;*/
    height: 100%;
}

body {
    font-family: "Hind Siliguri", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: flex;
    flex-direction: column;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

$paging-dots-container: 24px + 5;

.slider-container {
  .slider-frame {
    margin-bottom: $paging-dots-container;
  }

  .slider-control-bottomcenter {
    bottom: -$paging-dots-container !important;

    .paging-dots-container {
      top: 0 !important;
    }
  }
}
